@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --white: #fff;
  --dark: rgba(255, 255, 255, 0);
  --red: red;
  --light-grey: #f4f4f4;
  --grey: gray;
  --dark-gray: #666666;
  --black: black;
  --orange: orange;
}

body {
  font-family: "Roboto Condensed", sans-serif;
}

ul.no-bullets {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

nav {
  z-index: 2;
}

nav a.nav-link {
  font-weight: 500;
  letter-spacing: 0.0375rem;
  cursor: pointer;
  text-transform: uppercase;
}

.navbar-nav > li > a {
  color: #fff !important;
  color: var(--white) !important;
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  border: 0.1875rem solid rgba(255, 255, 255, 0);
  border: 0.1875rem solid var(--dark);
}

.navbar-nav > li > a.active,
.navbar-nav > li > a.active:focus {
  border-top: 0.1875rem solid;
  color: orange !important;
  color: var(--orange) !important;
}

.navbar-nav > li > a:hover {
  border-top: 0.1875rem solid;
  color: orange !important;
}

.header-wraper {
  position: relative;
  background: url(/static/media/Coding.8ca3514e.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.main-info {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Roboto Condensed", sans-serif;
  z-index: 1;
}

.main-info h1 {
  color: #fff;
  color: var(--white);
  text-transform: uppercase;
}

.typed-text {
  font-size: 2rem;
  color: #fff;
  color: var(--white);
}

@media (max-width: 768) {
  .main-info h1 {
    font-size: 1.7rem !important;
  }
  .typed-text {
    font-size: 1.3rem !important;
  }
}

.btn-main-offer {
  border: 1px solid #fff;
  border: 1px solid var(--white);
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 0;
  padding: 0.625rem 0.75rem 0.75rem 0.75rem;
  color: #fff;
  color: var(--white);
}

.btn-main-offer:hover {
  text-decoration: none;
  background-color: orange;
  background-color: var(--orange);
  color: #fff;
  color: var(--white);
  transition: 0.2 ease-in-out;
}

.tsparticles-canvas-el {
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 1;
}

@media (max-width: 768px) {
  .particles-canvas {
    display: none;
  }
}

.photo-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-img {
  width: 16rem;
  border-radius: 50%;
  padding: 0.3rem;
  border: 1px solid orange;
  border: 1px solid var(--orange);
}

.about-heading {
  color: orange;
  color: var(--orange);
  text-transform: uppercase;
}

@media (max-width: 468px) {
  .profile-img {
    width: 10rem;
  }
}

.skills {
  text-align: center;
  padding-bottom: 2rem;
  background-color: #f4f4f4;
  background-color: var(--light-grey);
}

.skills h1 {
  color: rgba(255, 255, 255, 0);
  color: var(--dark);
  text-transform: uppercase;
}

.skills .circle {
  position: relative;
  margin: 0.0375rem auto;
  background-color: orange;
  background-color: var(--orange);
  border-radius: 50%;
  width: 3.125rem;
  height: 3.125rem;
}

.skills .icon {
  font-size: 1.4rem;
  color: #fff;
  color: var(--white);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.skills .box {
  height: 19rem;
  border-bottom: 5px solid orange;
  border-bottom: 5px solid var(--orange);
  padding: 0.625rem;
  margin-bottom: 1.875rem;
  background-color: #fff;
  background-color: var(--white);
  transition: 0.3s ease-in-out;
}

.skills .box:hover {
  background-color: orange;
  background-color: var(--orange);
  border-bottom: 0.3125rem solid rgba(255, 255, 255, 0);
  border-bottom: 0.3125rem solid var(--dark);
}

.skills .box:hover .icon {
  color: orange;
  color: var(--orange);
}

.skills .box:hover .circle {
  background-color: #fff;
  background-color: var(--white);
}

.skills .box:hover ul {
  color: #fff;
  color: var(--white);
}

.experience {
  padding: 0.7rem;
}

.experience-wrapper {
  position: relative;
  padding: 3.125rem 0;
  overflow: hidden;
}

.experience h1 {
  text-transform: uppercase;
  color: orange;
  color: var(--orange);
}

.experience-wrapper::before {
  position: absolute;
  content: "";
  top: 0;
  left: 50%;
  margin-left: -0.0625rem;
  width: 0.125rem;
  height: 100%;
  background-color: gray;
  background-color: var(--grey);
  z-index: 1;
}

.timeline-block {
  width: calc(50% + 0.5rem);
  display: flex;
  justify-content: space-between;
  clear: both;
}

.timeline-block-right {
  float: right;
}

.timeline-block-left {
  float: left;
  direction: rtl;
}

.marker {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 0.3125rem solid gray;
  border: 0.3125rem solid var(--grey);
  background-color: orange;
  background-color: var(--orange);
  margin-top: 0.625rem;
  z-index: 3;
}

.timeline-content {
  width: 95%;
  padding: 0 0.9375rem;
  color: #666666;
  color: var(--dark-gray);
  direction: ltr;
}

.timeline-content h3 {
  margin-top: 0.3125rem;
  color: orange;
  color: var(--orange);
  font-weight: 900;
}

@media (max-width: 768px) {
  .experience .container {
    padding-left: 0;
  }
  .experience-wrapper::before {
    left: 0.5rem;
    margin-bottom: 1.875rem;
  }
  .timeline-block-right {
    float: none;
    width: 100%;
  }
  .timeline-block-left {
    float: none;
    width: 100%;
    direction: ltr;
  }
}

.port-wrapper {
  background-color: gray;
  background-color: var(--grey);
  padding: 3rem 0;
}

.port-wrapper h1 {
  color: orange;
  color: var(--orange);
}

.port-img {
  width: 15rem;
  height: 9rem;
  padding: 0 0.5rem;
}

.port-img-box {
  position: relative;
  margin: 1rem;
}

.overflow {
  position: absolute;
  opacity: 0;
  background-color: black;
  background-color: var(--black);
  width: 15rem;
  height: 8rem;
  top: 0;
  cursor: pointer;
}

.port-img-box:hover .overflow {
  opacity: 60%;
  transition: 0.3s ease-in-out;
  height: 9rem;
}

.port-icon {
  position: absolute;
  opacity: 0;
  margin-left: -8rem;
  margin-top: 3.6rem;
  color: orange;
  color: var(--orange);
  font-size: 1.5rem;
}

.port-img-box:hover .port-icon {
  opacity: 70%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.port-img-popup {
  /* width: 25rem; */
  height: 20rem;
  padding: 0 0.4rem;
}

.link {
  cursor: pointer;
  color: orange;
  color: var(--orange);
}

.link:hover {
  color: black;
  color: var(--black);
}

.popupbox-wrapper {
  margin-top: 2rem;
}

.popupbox-content {
  align-items: center;
  justify-content: center;
  text-align: center;
}

@media (max-width: 768px) {
  .port-img-popup {
    width: 100%;
  }
}

@media (max-height: 570px) {
  .popupbox-wrapper {
    height: 100%;
  }
  .port-img-popup {
    width: 50%;
  }
}

@media (max-height: 280px) {
  .popupbox-wrapper {
    height: 90%;
  }
  .port-img-popup {
    width: 30%;
  }
}

.contact {
  background-color: gray;
  background-color: var(--grey);
  padding: 3rem 0;
}

.contact h1 {
  color: orange;
  color: var(--orange);
  padding: 1.3rem 0;
  text-transform: uppercase;
}

.social-icon {
  background-color: gray;
  background-color: var(--grey);
  color: #fff;
  color: var(--white);
  margin: 0.5rem;
}

.footer {
  background-color: gray;
  background-color: var(--grey);
  color: #fff;
  color: var(--white);
  padding: 3rem;
}

.footer a {
  text-decoration: none;
  color: #fff;
  color: var(--white);
}

.footer a:hover {
  text-decoration: none;
  color: orange;
  color: var(--orange);
  cursor: pointer;
}

.footer p {
  margin-bottom: 0;
}

